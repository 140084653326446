import { ActionTree } from "vuex";
import { livaDataStateInterface } from "./state";
import { RootState } from "@/store/state";
import { livaDataMutations } from "./mutations";
import { fetchLiveTransactions } from "@/network/LiveDataService/LiveData";

export const actions: ActionTree<livaDataStateInterface, RootState> = {
	updateProgressiveJackpot(context, payload) {
		context.commit(livaDataMutations.SET_PROGRESSIVE_JACKPOT_VALUE, payload.jackpot);
	},
	async fetchLiveTransactionList(context, payload) {
		if (payload.transactionType == "Deposit") {
			const res = await fetchLiveTransactions(payload.transactionType);
			context.commit(livaDataMutations.SET_LIVE_DEPOSIT, res);
			return res;
		}
		if (payload.transactionType == "Withdrawal") {
			const res = await fetchLiveTransactions(payload.transactionType);
			context.commit(livaDataMutations.SET_LIVE_WITHDRAWAL, res);
			return res;
		}
	},
};
