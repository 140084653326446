import { MutationTree } from "vuex";
import { DepositGimmickStateInterface } from "./state";
import { DepositGimmicksResponse } from "@/types/DepositGimmicks/Response/DepositGimmicksResponse";

export enum DepositGimmickMutations {
  SET_DEPOSIT_GIMMICKS = "SET_DEPOSIT_GIMMICKS",
}

export const mutations: MutationTree<DepositGimmickStateInterface> = {
  [DepositGimmickMutations.SET_DEPOSIT_GIMMICKS](state, payload: DepositGimmicksResponse[]) {
    state.depositGimmick = payload;
  },
};