import { GetterTree } from "vuex";
import { RootState } from "@/store/state";
import { livaDataStateInterface } from "./state";
import { liveTransactionResponse } from "@/types/LiveData/Response/liveDataResponse";

export const getters: GetterTree<livaDataStateInterface, RootState> = {
	getProgressiveJackpotValue(state): number {
		return state.progressiveJackpotValue;
	},
	getLiveDepositList(state): liveTransactionResponse[] {
		return state.liveDepositList;
	},
	getLiveWithdrawalList(state): liveTransactionResponse[] {
		return state.liveWithdrawalList;
	},
};
