import { ActionTree } from "vuex";
import { getDepositGimmick } from "@/network/DepositGimmicksService/DepositGimmicks";
import { DepositGimmickStateInterface } from "./state";
import { RootState } from "@/store/state";
import { DepositGimmickMutations } from "./mutations";

export const actions: ActionTree<DepositGimmickStateInterface, RootState> = {
  async fetchDepositGimmicks(context) {
    if (context.state.depositGimmick.length > 0) {
      return;
    }
    const res = await getDepositGimmick();
    context.commit(DepositGimmickMutations.SET_DEPOSIT_GIMMICKS, res);
  },
};