<template>
	<div class="vendorListView">
		<div class="vendorList">
			<TransitionGroup name="list" tag="div" style="display: contents">
				<template v-for="vendor in vendorList" :key="vendor.id">
					<div class="vendorItem" :class="{ disabled: vendor?.comingSoon || vendor?.maintenance }">
						<UnifiedGameLauncher
							:objectType="'vendor'"
							:vendor="vendor"
							:category="category"
							@clickGame="emit('clickGame', vendor.id)"
						>
							<div class="vendorImageContainer">
								<BaseImage
									v-if="vipVendorList.includes(vendor.name)"
									class="vendorImage"
									:imgSrc="vendorImgPath + vendorCategoryPath + 'BTGVIP/' + projectName + '.png'"
									:isGameImg="true"
									:alt="vendor.name"
								/>
								<BaseImage
									v-else
									class="vendorImage"
									:imgSrc="vendorImgPath + vendorCategoryPath + vendor.name + fileExtension"
									:isGameImg="true"
									:alt="vendor.name"
								/>

								<div v-if="hotVendorList.includes(vendor.name.toUpperCase())" class="tag">
									<div class="tagText">{{ t("common.hot") }}</div>
								</div>
								<div v-if="vipVendorList.includes(vendor.name.toUpperCase())" class="tag vip">
									<div class="tagText vipText">{{ t("route.vip") }}</div>
								</div>

								<div v-if="vendor?.comingSoon" class="gameImgOverlay">
									<font-awesome-icon
										class="overlayIcon comingSoon"
										:icon="['fas', 'hourglass-half']"
									/>
									<div class="overlayText">
										{{ t("common.comingSoon") }}
									</div>
								</div>

								<div v-else-if="vendor?.maintenance" class="gameImgOverlay">
									<font-awesome-icon class="overlayIcon maintenance" :icon="['fas', 'gear']" />
									<div class="overlayText">
										{{ t("common.underMaintenance") }}
									</div>
								</div>

								<div v-else-if="vendor?.restricted" class="gameImgOverlay">
									<font-awesome-icon class="overlayIcon restricted" :icon="['fas', 'ban']" />
									<div class="overlayText">
										{{ t("common.restricted") }}
									</div>
								</div>
							</div>

							<div class="vendorTitle">
								{{ vendor.displayName }}
							</div>
						</UnifiedGameLauncher>
					</div>
				</template>
			</TransitionGroup>
		</div>
	</div>
</template>

<script setup>
import { ref, computed, defineProps, defineEmits, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import { useIsMobile } from "@/composables/useMobile";
import { useLoading } from "vue-loading-overlay";
import { GameType, hotVendorList, vipVendorList } from "@/common/GameType";
import { useToast } from "vue-toastification";
import { toastOptionSuccess, toastOptionError } from "@/composables/useToastOptions";
import { projectName } from "@/constant";
import UnifiedGameLauncher from "@/components/dynamic/UnifiedGameLauncher.vue";
import InputSearch from "@/components/dynamic/Inputs/InputSearch.vue";
import BaseImage from "@/components/dynamic/BaseImage.vue";

const { t, locale } = useI18n();
const store = useStore();
const router = useRouter();
const route = useRoute();
const isMobile = useIsMobile();
const $loading = useLoading();
const toast = useToast();

const props = defineProps({
	vendorList: {
		type: Array,
		default: () => [],
	},
	category: {
		type: Object,
		default: undefined,
	},
	assetPath: {
		type: String,
		default: "LaunchGame/",
	},
});

const emit = defineEmits(["clickGame"]);

const imgUrlDomain = process.env.VUE_APP_Media_CentralizedDomain_Domain;
const vendorImgPath = "VendorImage/" + props.assetPath;
const vendorCategoryPath = computed(() => {
	if (props.category?.name == "DOWNLOAD") return "SLOTS/";
	else return props.category?.name?.replace(" ", "_") + "/";
});
const fileExtension = ".png";
</script>

<style lang="sass" scoped>
.vendorListView
	margin-block: 2rem
.vendorList
	display: grid
	gap: 1rem 0.5rem
	grid-template-columns: repeat(4, 1fr)
	justify-content: space-around
	@media (max-width: 1199px)
		grid-template-columns: repeat(3, 1fr)
	@media (max-width: 767px)
		grid-template-columns: repeat(2, 1fr)
.vendorItem
	display: flex
	flex-direction: column
	align-items: center
	gap: 0.5rem
	&.disabled
		cursor: default
		.vendorImageContainer img
			filter: brightness(0.5)
	.tag
		position: absolute
		top: 0.25rem
		right: 0.25rem
		padding: 0.1rem 0.5rem
		border-radius: 999rem
		background: var(--danger)
		animation: glow 1.5s infinite
	.tagText
		color: var(--text-inverted)
		font-weight: var(--font-bold)
		font-size: 0.9em
		text-transform: uppercase
	.vip
		background: #fbf419
		animation: glowVIP 1.5s infinite
	.vipText
		color: #000
	.gameImgOverlay
		position: absolute
		height: 100%
		width: 100%
		top: 0
		left: 0
		display: flex
		flex-direction: column
		align-items: center
		justify-content: center
		gap: 0.5rem
		padding: 0.5rem
		color: var(--text-inverted)
	.overlayIcon
		font-size: 1.5em
		&.comingSoon
			animation-duration: 2s
		&.maintenance
			animation-duration: 5s
	.overlayText
		font-size: 0.7em
		font-weight: var(--font-black)
		font-style: italic
		text-transform: uppercase
		text-align: center
.vendorImageContainer
	width: 100%
	position: relative
	// aspect-ratio: 2/1
.vendorImage
	width: 100%
	// aspect-ratio: 2/1
	object-fit: contain
	// &:hover
		// filter: brightness(1.2)
		// -webkit-filter: brightness(1.2)
.vendorTitle
	text-align: center

// ====================== TRANSITION ======================

.list-move,
.list-enter-active,
.list-leave-active
	transition: all 0.4s ease-out !important

.list-leave-active
	position: absolute
	display: none

.list-enter-from
	opacity: 0
	transform: translate(0, 2rem)
// .list-leave-to
// 	opacity: 0

// ====================== TRANSITION ======================
</style>
